<template>
  <div>

    <b-overlay
      :show="$apollo.loading || mutationLoading"
      rounded="sm"
    >
      <b-card title="Manage Sessions">

        <!-- search input -->
        <div class="custom-search d-flex justify-content-start">
          <b-form-group
            class="form-inline"
            label="Search"
            label-size="sm"
          >
            <b-form-input
              v-model="searchTerm"
              class="d-inline-block mr-1"
              placeholder="Search meeting"
              type="text"
            />
            <b-button
              variant="primary mr-1"
              @click="() => { resetScheduleMeetingForm(); $bvModal.show('scheduleMeetingModal') }"
            >
              <feather-icon icon="ClockIcon" />
              Schedule Session
            </b-button>
            <b-button
              v-b-modal.addSlotModal
              variant="outline-success mr-1"
            >
              <feather-icon icon="CalendarIcon" />
              {{ incubatorSlots.length ? 'Update Slots' : 'Add Slots' }}
            </b-button>
            <!-- <b-button
              v-b-modal.manageSlotModal
              variant="outline-secondary mr-1"
            >
              <feather-icon icon="CalendarIcon" />
              Manage Slot
            </b-button> -->
            <b-button
              v-if="$route.params.mid"
              variant="outline-primary mr-1"
              @click="$router.replace(`/programs/${$route.params.id}/manage#Sessions`)"
            >
              Show All
            </b-button>
          </b-form-group>
        </div>
        <!-- table -->
        <vue-good-table
          :columns="columns"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          :rows="rows"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Action -->
            <span
              v-if="props.column.field === 'action'"
              class="d-flex"
            >
              <b-button
                v-b-tooltip="'Update Links'"
                class="mr-50"
                size="sm"
                variant="outline-primary"
                @click="selectedRow = props.row.originalIndex; $bvModal.show('updateLinks')"
              >
                <feather-icon
                  icon="LinkIcon"
                />
              </b-button>
              <!-- <b-button
                v-b-tooltip="'Add Participants'"
                size="sm"
                variant="outline-primary"
                @click="selectedRow = props.row.originalIndex; $bvModal.show('addParticipants')"
              >
                <feather-icon
                  icon="UserPlusIcon"
                />
              </b-button> -->
            </span>

            <span v-else-if="props.column.field === 'buttons'">
              <b-button
                :href="prependHttps(props.row.meeting_link)"
                class="mb-25 mr-25"
                size="sm"
                target="_blank"
                variant="outline-primary"
                :disabled="!prependHttps(props.row.meeting_link)"
              >Join meeting</b-button>
              <b-button
                v-if="prependHttps(props.row.recording_link)"
                :href="prependHttps(props.row.recording_link)"
                size="sm"
                target="blank"
                variant="outline-primary"
              >View recording</b-button>
            </span>

            <span v-else-if="props.column.label === 'Meeting'">

              {{ props.row.title }}<br> <b-badge
                class="mt-25"
                variant="light-primary"
              >{{ props.row.type }}</b-badge>
            </span>

            <span v-else-if="props.column.label === 'Time'">
              <span v-if="props.formattedRow[props.column.field] !== '-'">
                {{ props.formattedRow[props.column.field] }}
              </span>
              <b-button
                size="sm"
                variant="outline-primary"
                @click="generateScheduleClock(); scheduleParticipantIds = props.row.sessions_participanttables; bookedbegindatetimestamp = props.row.begin_time; bookedenddatetimestamp = props.row.end_time; setAvailability(props.row); $bvModal.show('scheduleSlotModal')"
              >
                {{ props.formattedRow[props.column.field] === '-' ? 'Schedule' : 'Re-Schedule' }}
              </b-button>
            </span>

            <span v-else-if="props.column.field === 'status'">
              <b-badge
                :variant="statusVariant(props.formattedRow[props.column.field])"
                class="mb-25"
              >{{ props.formattedRow[props.column.field] }}</b-badge>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <template
            #column-filter="{ column, updateFilters }"
          >
            <span v-if="column.label === 'Operation'">
              <treeselect
                :append-to-body="true"
                :normalizer="filterNormalizer"
                :options="operations"
                class="form-control p-0 border-0"
                placeholder="Select Phase, Subphase or Activities"
                style="border-collapse: separate"
                @input="(val) => updateFilters(column, val)"
              />
            </span>
          </template>
          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['3','5','10']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :per-page="pageLength"
                  :total-rows="props.total"
                  :value="1"
                  class="mt-1 mb-0"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </b-overlay>
    <b-modal
      id="scheduleSlotModal"
      ok-title="Save"
      no-close-on-backdrop
      size="lg"
      title="Schedule"
      @ok="addSchedule"
    >
      <b-row v-if="scheduleModalTab !== 0">
        <b-col>
          <b-button
            class="mb-1"
            variant="light"
            @click="scheduleModalTab = 0"
          >
            <feather-icon
              icon="HomeIcon"
            />
            <span class="ml-1">Home</span>
          </b-button>
        </b-col>
      </b-row>
      <div v-if="scheduleModalTab === 0">
        <b-row>
          <b-col md="12">
            <b-button
              :variant="`${scheduleChoice === 'addslot' ? 'primary' : 'outline-primary'}`"
              size="sm"
              class="mb-1"
              @click="toggleChoice('addslot')"
            >
              Add Slots
            </b-button>
            <b-button
              :variant="`${scheduleChoice === 'direct' ? 'primary' : 'outline-primary'}`"
              size="sm"
              class="ml-1 mb-1"
              @click="toggleChoice('direct')"
            >
              Direct Schedule
            </b-button>
          </b-col>
          <b-col md="12">
            <vue-good-table
              :columns="scheduleColumns"
              :pagination-options="{
                enabled: true,
                perPage:pageLength
              }"
              :rows="availableslots"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm }"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.label === 'Time'">
                  <span v-if="props.formattedRow[props.column.field] !== '-'">
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </span>
                <span v-else-if="props.column.field === 'availablemembers'">
                  <Promised
                    :promise="getAvailableMembers(props.row.begindatetimestamp, props.row.enddatetimestamp, props.row)"
                  >
                    <!-- <template v-slot:pending>
                      <b-button
                        class="mr-50"
                        disabled
                        size="sm"
                        variant="outline-primary"
                      ><feather-icon icon="ClockIcon" /></b-button>
                    </template> -->
                    <template #default="data">
                      <span
                        v-if="data.data.length > 0"
                        class="text-primary d-flex justify-content-between align-items-center"
                      >
                        <span>
                          {{ data.data.length }} :: ({{ parseFloat((data.data.length / availabilitycount) * 100).toFixed(2) }}%)
                        </span>
                        <span>
                          <b-button
                            v-if="data.data.length > 0"
                            variant="outline-primary"
                            size="sm"
                            @click="scheduleModalTab = 2; availablemembers = data.data"
                          >
                            View
                          </b-button>
                          <b-button
                            v-if="data.row.begindatetimestamp === bookedbegindatetimestamp && data.row.enddatetimestamp === bookedenddatetimestamp"
                            variant="success"
                            class="ml-50"
                            size="sm"
                          >
                            Booked
                          </b-button>
                          <b-button
                            v-else
                            variant="primary"
                            class="ml-50"
                            size="sm"
                            @click="bookSlot(data.row)"
                          >
                            Book Slot
                          </b-button>
                        </span>
                      </span>
                      <span v-else>None</span>
                    </template>
                    <template #rejected>
                      <span>
                        None
                      </span>
                    </template>
                  </Promised>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Showing 1 to
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['3','5','10']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                  </div>
                  <div>
                    <b-pagination
                      :per-page="pageLength"
                      :total-rows="props.total"
                      :value="1"
                      class="mt-1 mb-0"
                      first-number
                      last-number
                      next-class="next-item"
                      prev-class="prev-item"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-col>
          <b-col
            cols="12"
            :class="`${scheduleChoice !== 'direct' ? 'd-none' : ''}`"
          >
            <h6 class="my-1">
              Direct Schedule
            </h6>
            <b-row>
              <b-col md="4">
                <b-form-group
                  class="mb-2"
                  label="Date"
                  label-for="StartDate"
                >
                  <flat-pickr
                    id="StartDate"
                    v-model="schedulebeginDate"
                    :config="{ dateFormat: 'Y-m-d'}"
                    class="form-control"
                    placeholder="Select start date"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  class="mb-2"
                  label="Start Time"
                  label-for="StartTime"
                >
                  <div
                    ref="sbt"
                    class="timepicker-ui"
                  >
                    <b-form-input
                      v-model="schedulebeginTime"
                      type="text"
                      class="timepicker-ui-input"
                      placeholder="Select end time"
                    />
                  </div>
                </b-form-group>
              </b-col>
              <!-- end date and time -->
              <b-col md="4">
                <b-form-group
                  class="mb-2"
                  label="End Time"
                  label-for="EndTime"
                >
                  <div
                    ref="set"
                    class="timepicker-ui"
                  >
                    <b-form-input
                      v-model="scheduleendTime"
                      type="text"
                      class="timepicker-ui-input"
                      placeholder="Select end time"
                    />
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            v-if="shceduleChoice === 'addslot'"
            cols="12"
          >
            <h6 class="my-1">
              Add Slots
            </h6>
            <b-row>
              <b-col md="4">
                <b-form-group
                  class="mb-2"
                  label="Date"
                  label-for="StartDate"
                >
                  <flat-pickr
                    id="StartDate"
                    v-model="schedulebeginDateR"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d'}"
                    class="form-control"
                    placeholder="Select start date"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  class="mb-2"
                  label="Start Time"
                  label-for="StartTime"
                >
                  <div
                    ref="sbtr"
                    class="timepicker-ui"
                  >
                    <b-form-input
                      v-model="schedulebeginTimeR"
                      type="text"
                      class="timepicker-ui-input"
                      placeholder="Select end time"
                    />
                  </div>
                </b-form-group>
              </b-col>
              <!-- end date and time -->
              <b-col md="3">
                <b-form-group
                  class="mb-2"
                  label="End Time"
                  label-for="EndTime"
                >
                  <div
                    ref="setr"
                    class="timepicker-ui"
                  >
                    <b-form-input
                      v-model="scheduleendTimeR"
                      type="text"
                      class="timepicker-ui-input"
                      placeholder="Select end time"
                    />
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-button
                  variant="primary"
                  block
                  class="mt-2"
                  @click="addScheduleRequestingSlot()"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-1"
                  />
                  <span>Add</span>
                </b-button>
              </b-col>
            </b-row>
            <h6 v-if="scheduleslotRequests.length > 0">
              Alternative Dates
            </h6>
            <b-row>
              <b-col cols="12">
                <b-badge
                  v-for="(slot, i) in scheduleslotRequests"
                  :key="i"
                  variant="primary"
                  class="mb-50 mr-50"
                >
                  {{ `${new Date(slot.begin)
                    .toLocaleString('en-US', {
                      month: 'short',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      timeZone: gotUserData.timezone.abbr,
                      hour12: true,
                    })} - ${new Date(slot.end)
                    .toLocaleString('en-US', {
                      hour: 'numeric',
                      minute: 'numeric',
                      timeZone: gotUserData.timezone.abbr,
                      hour12: true,
                    })}` }}
                  <feather-icon
                    style="cursor: pointer;"
                    icon="XIcon"
                    class="ml-1"
                    @click="removeScheduleRequestingSlot(i)"
                  />
                </b-badge>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div v-if="scheduleModalTab === 1">
        <b-row>
          <b-col md="12">
            <vue-good-table
              :columns="memberColumns"
              :pagination-options="{
                enabled: true,
                perPage:pageLength
              }"
              :rows="availablemembers"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm }"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.label === 'Type'">
                  <b-badge variant="light-primary">
                    {{ props.formattedRow[props.column.field] }}
                  </b-badge>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Showing 1 to
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['3','5','10']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                  </div>
                  <div>
                    <b-pagination
                      :per-page="pageLength"
                      :total-rows="props.total"
                      :value="1"
                      class="mt-1 mb-0"
                      first-number
                      last-number
                      next-class="next-item"
                      prev-class="prev-item"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal
      id="scheduleMeetingModal"
      ok-title="Save"
      no-close-on-backdrop
      size="lg"
      title="Schedule Session"
      :footer-class="modalTab === 0 || (modalTab === 1 && showInstruction) ? 'd-none' : ''"
      @ok="addMeeting"
    >
      <b-row v-if="modalTab !== 0">
        <b-col>
          <b-button
            class="mb-1"
            variant="light"
            @click="modalTab = modalTab - 1"
          >
            <feather-icon
              icon="ChevronLeftIcon"
            />
            <span class="ml-1">Back</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row v-if="modalTab === 0">
        <b-col>
          <h5>
            Would you like to schedule a session under particular program, event or competition?
          </h5>
          <div>
            <b-button
              class="my-1 mr-1"
              variant="outline-danger"
              @click="modalTab = 1; showInstruction = true;"
            >
              Yes
            </b-button>
            <b-button
              class="my-1"
              variant="primary"
              @click="modalTab = 1; showInstruction = false;"
            >
              No
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="modalTab === 1 && showInstruction">
        <b-col>
          <h5>
            To schedule sessions under a particular program, event or competition, kindly do so from opportunities, programs or events or competition manager.
          </h5>
        </b-col>
      </b-row>
      <b-row
        v-if="modalTab === 1 && !showInstruction"
        class="justify-content-center"
      >
        <b-col
          md="4"
          class="px-1"
        >
          <div
            class="px-1 py-2"
            style="border: solid 1px #7367F0; border-radius: 10px; color: #7367F0; cursor: pointer;"
            @click="modalTab = 2; choice = 'slot'"
          >
            <div class="d-flex justify-content-center">
              <feather-icon
                icon="ListIcon"
                size="32"
              />
            </div>
            <h6 class="text-center text-primary mt-1">
              Choose Slot
            </h6>
          </div>
        </b-col>
        <b-col
          md="4"
          class="px-1"
        >
          <div
            class="px-1 py-2"
            style="border: solid 1px #7367F0; border-radius: 10px; color: #7367F0; cursor: pointer;"
            @click="modalTab = 2; choice = 'direct'; generateDirectClock();"
          >
            <div class="d-flex justify-content-center">
              <feather-icon
                icon="LayoutIcon"
                size="32"
              />
            </div>
            <h6 class="text-center text-primary mt-1">
              Direct Schedule
            </h6>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="modalTab === 2 && choice === 'direct'">
        <b-col md="12">
          <b-form-group
            label="Title"
            label-for="title"
          >
            <b-form-input
              id="title"
              v-model="title"
              placeholder="Meeting title"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Description"
            label-for="description"
          >
            <b-form-textarea
              id="description"
              v-model="description"
              placeholder="Enter Description"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Select Category"
            label-for="selectCategory"
          >
            <v-select
              id="selectCategory"
              v-model="startupType"
              :get-option-label="option => option.title"
              :options="[{title: 'Pre-Incubatee', value: 'pre-incubatee'},{title: 'Incubatee', value: 'incubatee'},{title: 'Graduate', value: 'graduate'}]"
              :reduce="option => option.value"
              placeholder="Select Category"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Select Startups"
            label-for="selectStartups"
          >
            <v-select
              id="selectStartups"
              v-model="selectedStartup"
              :get-option-label="option => option.users_organizationtable.title"
              :options="startups.filter(startup => startup.role === startupType)"
              :reduce="option => option.users_organizationtable.id"
              multiple
              placeholder="Select Startups"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Select Partners"
            label-for="selectPartners"
          >
            <v-select
              id="selectPartners"
              v-model="selectedPartner"
              :get-option-label="option => option.title"
              :options="partners.filter(partner => partner.type === 'partner')"
              multiple
              placeholder="Select Partners"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Select Mentors"
            label-for="selectMentors"
          >
            <v-select
              id="selectMentors"
              v-model="selectedMentor"
              :get-option-label="option => option.full_name"
              :options="partners.filter(partner => partner.type === 'mentor')"
              multiple
              placeholder="Select Partners"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="d-flex align-items-center mb-50"
        >
          <h6 class="mt-25">
            Request Slot:
          </h6>
          <b-form-checkbox
            v-model="requestSlot"
            class="ml-50 custom-control-primary"
            name="check-button"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </b-col>

        <!-- start date and time -->
        <b-col
          cols="12"
          :class="`${requestSlot ? 'd-none' : ''}`"
        >
          <b-row>
            <b-col md="4">
              <b-form-group
                class="mb-2"
                label="Date"
                label-for="StartDate"
              >
                <flat-pickr
                  id="StartDate"
                  v-model="beginDate"
                  :config="{ enableTime: false, dateFormat: 'Y-m-d'}"
                  class="form-control"
                  placeholder="Select start date"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                class="mb-2"
                label="Start Time"
                label-for="StartTime"
              >
                <div
                  ref="bt"
                  class="timepicker-ui"
                >
                  <b-form-input
                    v-model="beginTime"
                    type="text"
                    class="timepicker-ui-input"
                    placeholder="Select end time"
                  />
                </div>
              </b-form-group>
            </b-col>
            <!-- end date and time -->
            <b-col md="4">
              <b-form-group
                class="mb-2"
                label="End Time"
                label-for="EndTime"
              >
                <div
                  ref="et"
                  class="timepicker-ui"
                >
                  <b-form-input
                    v-model="endTime"
                    type="text"
                    class="timepicker-ui-input"
                    placeholder="Select end time"
                  />
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          :class="`${!requestSlot ? 'd-none' : ''}`"
        >
          <b-row>
            <b-col md="4">
              <b-form-group
                class="mb-2"
                label="Date"
                label-for="StartDate"
              >
                <flat-pickr
                  id="StartDate"
                  v-model="beginDateR"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d'}"
                  class="form-control"
                  placeholder="Select start date"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                class="mb-2"
                label="Start Time"
                label-for="StartTime"
              >
                <div
                  ref="btr"
                  class="timepicker-ui"
                >
                  <b-form-input
                    v-model="beginTimeR"
                    type="text"
                    class="timepicker-ui-input"
                    placeholder="Select end time"
                  />
                </div>
              </b-form-group>
            </b-col>
            <!-- end date and time -->
            <b-col md="3">
              <b-form-group
                class="mb-2"
                label="End Time"
                label-for="EndTime"
              >
                <div
                  ref="etr"
                  class="timepicker-ui"
                >
                  <b-form-input
                    v-model="endTimeR"
                    type="text"
                    class="timepicker-ui-input"
                    placeholder="Select end time"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-button
                variant="primary"
                block
                class="mt-2"
                @click="addRequestingSlot()"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-1"
                />
                <span>Add</span>
              </b-button>
            </b-col>
          </b-row>
          <h6 v-if="slotRequests.length > 0">
            Alternative Dates
          </h6>
          <b-row>
            <b-col cols="12">
              <b-badge
                v-for="(slot, i) in slotRequests"
                :key="i"
                variant="primary"
                class="mb-50 mr-50"
              >
                {{ `${new Date(slot.begin)
                  .toLocaleString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    timeZone: gotUserData.timezone.abbr,
                    hour12: true,
                  })} - ${new Date(slot.end)
                  .toLocaleString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                    timeZone: gotUserData.timezone.abbr,
                    hour12: true,
                  })}` }}
                <feather-icon
                  style="cursor: pointer;"
                  icon="XIcon"
                  class="ml-1"
                  @click="removeRequestingSlot(i)"
                />
              </b-badge>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          v-if="selectedStartup"
          md="12"
        >
          <b-row>
            <b-col md="12">
              <h6>Additional Information (Optional)</h6>
            </b-col>
            <b-col
              v-if="!selectedExercise && !selectedMilestone"
            >
              <b-form-group
                label="Module"
                label-for="operation"
              >
                <treeselect
                  id="operation"
                  v-model="selectedOperation"
                  :normalizer="normalizer"
                  :options="operations"
                  placeholder="Select Module"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="!selectedOperation && !selectedMilestone"
            >
              <b-form-group
                label="Exercises"
                label-for="exercises"
              >
                <treeselect
                  id="exercises"
                  v-model="selectedExercise"
                  :normalizer="exercisesnormalizer"
                  :options="exercises"
                  placeholder="Select Exercise"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="selectedStartup && selectedStartup.length === 1 && !selectedOperation && !selectedExercise"
            >
              <b-form-group
                label="Milestone"
                label-for="milestone"
              >
                <treeselect
                  id="milestone"
                  v-model="selectedMilestone"
                  :normalizer="milestonesnormalizer"
                  :options="milestones"
                  placeholder="Select Milestone"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div v-if="modalTab === 2 && choice === 'slot'">
        <book-slots :associations="incubatorAssociations" />
      </div>
    </b-modal>
    <b-modal
      id="addSlotModal"
      ok-title="Save"
      no-close-on-backdrop
      size="lg"
      :title="incubatorSlots.length ? 'Update Slots' : 'Add Slots'"
      hide-cancel
      ok-only
      @ok="updateSlots"
    ><add-slots
      :existing-slots="incubatorSlots"
      @update-slots="(value) => { newIncubatorSlots = value }"
    /></b-modal>
    <b-modal
      id="manageSlotModal"
      ok-title="Okay"
      ok-only
      no-close-on-backdrop
      size="xl"
      title="Manage Slots"
      @ok="addMeeting"
    >
      <b-row>
        <b-col md="12">
          <calendar />
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      v-if="rows.length"
      id="updateLinks"
      ok-title="Save"
      no-close-on-backdrop
      title="Update Meeting Links"
      @ok="updateLinks"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Meeting Link"
            label-for="updateMeetingLink"
          >
            <b-form-input
              id="updateMeetingLink"
              v-model="rows[selectedRow].meeting_link"
              placeholder="Meeting Link"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Recording Link"
            label-for="updateRecordingLink"
          >
            <b-form-input
              id="updateRecordingLink"
              v-model="rows[selectedRow].recording_link"
              placeholder="Recording Link"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="addParticipants"
      ok-only
      ok-title="Save"
      title="Add Participants"
      no-close-on-backdrop
      @ok="updateParticipants"
    >
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Select Startups"
            label-for="selectStartups"
          >
            <v-select
              id="selectStartups"
              v-model="startupParticipants"
              :get-option-label="option => option.title"
              :options="startups"
              :reduce="option => option.id"
              multiple
              placeholder="Select Startups"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Select Partners"
            label-for="selectPartners"
          >
            <v-select
              id="selectPartners"
              v-model="partnerParticipants"
              :get-option-label="option => option.full_name"
              :options="partners"
              :reduce="option => option.partner_id"
              multiple
              placeholder="Select Partners"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>

import {
  BBadge,
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BPagination,
  BRow,
  BFormCheckbox,
  BCard,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import Treeselect from '@riophae/vue-treeselect'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Calendar from '@/views/incubator-portal/programs/components/calendar/Slotcalendar.vue'
import { TimepickerUI } from 'timepicker-ui'
import { getUserData } from '@/utils/auth'
import { Promised } from 'vue-promised'
import AddSlots from '@/views/incubator-portal/sessions/components/AddSlots.vue'
import BookSlots from '@/views/incubator-portal/sessions/components/BookSlots.vue'

export default {
  components: {
    BookSlots,
    AddSlots,
    BRow,
    BCol,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BBadge,
    BCard,
    // FormBuilder,
    Calendar,
    vSelect,
    Treeselect,
    flatPickr,
    BFormCheckbox,
    Promised,
  },
  data() {
    return {
      newIncubatorSlots: [],
      incubatorSlots: [],
      incubatorAssociations: [],
      title: '',
      type: '',
      slotdate: '',
      program: null,
      selectedRowId: null,
      slotbegindatetimestamp: null,
      slotenddatetimestamp: null,
      bookedbegindatetimestamp: null,
      bookedenddatetimestamp: null,
      choiceslotdate: '',
      slotbeginTime: null,
      slotendTime: null,
      bookedslots: [],
      choicebookedslots: [],
      selectedslots: [],
      availableslots: [],
      availabilitycount: 0,
      availablemembers: [],
      globalavailablemembers: [],
      slotRequests: [],
      scheduleslotRequests: [],
      scheduleParticipantIds: [],
      beginDate: null,
      beginTime: null,
      endTime: null,
      beginDateR: null,
      beginTimeR: null,
      endTimeR: null,
      schedulebeginDate: null,
      schedulebeginTime: null,
      scheduleendTime: null,
      schedulebeginDateR: null,
      schedulebeginTimeR: null,
      scheduleendTimeR: null,
      endDate: null,
      meetingLink: '',
      description: '',
      startuporpartner: null,
      operationsId: null,
      modalTab: 0,
      scheduleModalTab: 0,
      choice: '',
      scheduleChoice: '',
      status: '',
      mutationLoading: false,
      is_general: !this.getIncubatorFromSubdomain(),
      requestSlot: false,
      showInstruction: false,
      participants: [],
      selectedStartup: null,
      startupType: null,
      selectedPartner: null,
      selectedMentor: null,
      selectedSlot: null,
      gotUserData: getUserData(),
      meetingTypeOptions: [
        {
          label: 'webinar',
          id: 1,
        },
        {
          label: 'one-on-one',
          id: 2,
        },
        {
          label: 'multi participant',
          id: 3,
        },
      ],
      searchTerm: '',
      selectedRow: 0,
      pageLength: 5,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Meeting',
          field(row) {
            return `${row.title} - ${row.type}`
          },
          tdClass: 'text-capitalize',
        },
        // {
        //   label: 'Operation',
        //   field(row) {
        //     return row.programs_operationstable.title
        //   },
        //   filterOptions: {
        //     enabled: true, // enable filter for this column
        //     customFilter: true, // use custom filter component
        //   },
        // },
        {
          label: 'Time',
          field(row) {
            if (!row.begin_time) return '-'
            return `${new Date(row.begin_time)
              .toLocaleString('en-US', {
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                timeZone: getUserData().timezone.abbr,
                hour12: true,
              })} - ${new Date(row.end_time)
              .toLocaleString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                timeZone: getUserData().timezone.abbr,
                hour12: true,
              })}`
          },
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Links',
          field: 'buttons',
          width: '11rem',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      scheduleColumns: [
        {
          label: 'Slot',
          field(row) {
            if (!row.begindatetimestamp) return '-'
            return `${new Date(row.begindatetimestamp)
              .toLocaleString('en-US', {
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                timeZone: getUserData().timezone.abbr,
                hour12: true,
              })} - ${new Date(row.enddatetimestamp)
              .toLocaleString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                timeZone: getUserData().timezone.abbr,
                hour12: true,
              })}`
          },
        },
        {
          label: 'Available Members',
          field: 'availablemembers',
        },
      ],
      memberColumns: [
        {
          label: 'Name',
          field(row) {
            if (row.programs_startupparticipant.programs_applicantstable) {
              if (row.programs_startupparticipant.programs_applicantstable.users_organizationtable) {
                return row.programs_startupparticipant.programs_applicantstable.users_organizationtable.title
              }
              return row.programs_startupparticipant.programs_applicantstable.users_customuser.full_name
            }
            if (row.programs_partnerstable.users_organizationtable) {
              return row.programs_partnerstable.users_organizationtable.title
            }
            return row.programs_partnerstable.users_customuser.full_name
          },
        },
        {
          label: 'Type',
          field(row) {
            if (row.programs_startupparticipant.programs_applicantstable) {
              if (row.programs_startupparticipant.programs_applicantstable.users_organizationtable) {
                return 'Startup'
              }
              return 'Student'
            }
            if (row.programs_partnerstable.users_organizationtable) {
              return 'Partner'
            }
            return 'Mentor'
          },
        },
      ],
      rows: [],
      selectedOperation: null,
      selectedExercise: null,
      selectedMilestone: null,
      startups: [],
      partners: [],
      exercises: [],
      milestones: [],
    }
  },
  computed: {
    allAvailableMembers() {
      const members = this.availablemembers
      // console.log(this.availablemembers)
      const temp = this.globalavailablemembers
      members.map(details => {
        temp.push({
          ...details,
          begindatetimestamp: this.slotbegindatetimestamp,
          enddatetimestamp: this.slotenddatetimestamp,
        })
        // console.log(temp)
        this.globalavailablemembers = temp
        return ({
          ...details,
          begindatetimestamp: this.slotbegindatetimestamp,
          enddatetimestamp: this.slotenddatetimestamp,
        })
      })
      return members
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Scheduled: 'light-primary',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
  },
  methods: {
    resetScheduleMeetingForm() {
      // Reset all form elements inside the Schedule Meeting Modal
      this.schedulebeginDate = null
      this.schedulebeginTime = null
      this.scheduleendTime = null
      this.schedulebeginDateR = null
      this.schedulebeginTimeR = null
      this.scheduleendTimeR = null
      this.scheduleParticipantIds = []
      this.scheduleslotRequests = []
    },
    generateClock() {
      setTimeout(() => {
        const bd = new TimepickerUI(this.$refs.bd, {
          editable: true,
          preventDefault: false,
          color: '#7367F0',
        })
        bd.create()
        this.$refs.bd.addEventListener('accept', ({ detail }) => {
          if (this.checkConflicts(this.generateUTCDateTime(this.slotdate, detail), 'start')) {
            this.slotbeginTime = this.generateUTCDateTime(this.slotdate, detail)
          } else {
            this.slotbeginTime = ''
          }
        })
        const ed = new TimepickerUI(this.$refs.ed, {
          editable: true,
          preventDefault: false,
          color: '#7367F0',
        })
        ed.create()
        this.$refs.ed.addEventListener('accept', ({ detail }) => {
          if (this.checkConflicts(this.generateUTCDateTime(this.slotdate, detail), 'end')) {
            this.slotendTime = this.generateUTCDateTime(this.slotdate, detail)
          } else {
            this.slotendTime = ''
          }
        })
      }, 1000)
    },
    generateDirectClock() {
      setTimeout(() => {
        const bt = new TimepickerUI(this.$refs.bt, {
          editable: true,
          preventDefault: false,
          color: '#7367F0',
        })
        bt.create()
        this.$refs.bt.addEventListener('accept', ({ detail }) => {
          if (this.checkTimeConflict(this.generateUTCDateTime(this.beginDate, detail), 'start')) {
            this.beginTime = this.generateUTCDateTime(this.beginDate, detail)
          } else {
            this.beginTime = ''
          }
        })
        const et = new TimepickerUI(this.$refs.et, {
          editable: true,
          preventDefault: false,
          color: '#7367F0',
        })
        et.create()
        this.$refs.et.addEventListener('accept', ({ detail }) => {
          if (this.checkTimeConflict(this.generateUTCDateTime(this.beginDate, detail), 'end')) {
            this.endTime = this.generateUTCDateTime(this.beginDate, detail)
          } else {
            this.endTime = ''
          }
        })
        const btr = new TimepickerUI(this.$refs.btr, {
          editable: true,
          preventDefault: false,
          color: '#7367F0',
        })
        btr.create()
        this.$refs.btr.addEventListener('accept', ({ detail }) => {
          if (this.checkTimeConflict(this.generateUTCDateTime(this.beginDateR, detail), 'start')) {
            this.beginTimeR = this.generateUTCDateTime(this.beginDateR, detail)
          } else {
            this.beginTimeR = ''
          }
        })
        const etr = new TimepickerUI(this.$refs.etr, {
          editable: true,
          preventDefault: false,
          color: '#7367F0',
        })
        etr.create()
        this.$refs.etr.addEventListener('accept', ({ detail }) => {
          if (this.checkTimeConflict(this.generateUTCDateTime(this.beginDateR, detail), 'end')) {
            this.endTimeR = this.generateUTCDateTime(this.beginDateR, detail)
          } else {
            this.endTimeR = ''
          }
        })
      }, 1000)
    },
    generateScheduleClock() {
      setTimeout(() => {
        const sbt = new TimepickerUI(this.$refs.sbt, {
          editable: true,
          preventDefault: false,
          color: '#7367F0',
        })
        sbt.create()
        this.$refs.sbt.addEventListener('accept', ({ detail }) => {
          if (this.checkTimeConflict(this.generateUTCDateTime(this.schedulebeginDate, detail), 'start')) {
            this.schedulebeginTime = this.generateUTCDateTime(this.schedulebeginDate, detail)
          } else {
            this.schedulebeginTime = ''
          }
        })
        const set = new TimepickerUI(this.$refs.set, {
          editable: true,
          preventDefault: false,
          color: '#7367F0',
        })
        set.create()
        this.$refs.set.addEventListener('accept', ({ detail }) => {
          if (this.checkTimeConflict(this.generateUTCDateTime(this.schedulebeginDate, detail), 'end')) {
            this.scheduleendTime = this.generateUTCDateTime(this.schedulebeginDate, detail)
          } else {
            this.scheduleendTime = ''
          }
        })
        const sbtr = new TimepickerUI(this.$refs.sbtr, {
          editable: true,
          preventDefault: false,
          color: '#7367F0',
        })
        sbtr.create()
        this.$refs.sbtr.addEventListener('accept', ({ detail }) => {
          if (this.checkTimeConflict(this.generateUTCDateTime(this.schedulebeginDateR, detail), 'start')) {
            this.schedulebeginTimeR = this.generateUTCDateTime(this.schedulebeginDateR, detail)
          } else {
            this.schedulebeginTimeR = ''
          }
        })
        const setr = new TimepickerUI(this.$refs.setr, {
          editable: true,
          preventDefault: false,
          color: '#7367F0',
        })
        setr.create()
        this.$refs.setr.addEventListener('accept', ({ detail }) => {
          if (this.checkTimeConflict(this.generateUTCDateTime(this.schedulebeginDateR, detail), 'end')) {
            this.scheduleendTimeR = this.generateUTCDateTime(this.schedulebeginDateR, detail)
          } else {
            this.scheduleendTimeR = ''
          }
        })
      }, 1000)
    },
    addScheduleRequestingSlot() {
      const temp = this.scheduleslotRequests
      temp.push({
        date: this.schedulebeginDateR,
        begin: this.schedulebeginTimeR,
        end: this.scheduleendTimeR,
      })
      this.scheduleslotRequests = temp
    },
    removeScheduleRequestingSlot(index) {
      const temp = this.scheduleslotRequests
      temp.splice(index, 1)
      this.scheduleslotRequests = temp
    },
    addRequestingSlot() {
      const temp = this.slotRequests
      temp.push({
        date: this.beginDateR,
        begin: this.beginTimeR,
        end: this.endTimeR,
      })
      this.slotRequests = temp
    },
    removeRequestingSlot(index) {
      const temp = this.slotRequests
      temp.splice(index, 1)
      this.slotRequests = temp
    },
    generateUTCDateTime(date, time) {
      return (new Date(new Date((new Date(date)).getFullYear(), (new Date(date)).getMonth(), (new Date(date)).getDate(), this.getHourIn24(time.hour, time.type), time.minutes).toLocaleString('en-US', { timeZone: getUserData().timezone.abbr })).toISOString())
    },
    getHourIn24(hour, type) {
      if (hour === 12 && type === 'AM') {
        return '00'
      }
      if (type === 'PM' && hour < 12) {
        return parseInt(hour, 10) + 12
      }
      return hour
    },
    checkConflicts(check, type) {
      let found = 0
      if (type === 'end' && (new Date(check)).getTime() <= (new Date(this.begintime)).getTime()) {
        found = 1
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'There must be a 15 Minutes Slot',
            icon: 'XIcon',
            variant: 'warning',
          },
        })
      }
      this.selectedslots.map(slot => {
        if ((new Date(check)).getTime() > (new Date(slot.begintime)).getTime() && (new Date(check)).getTime() < (new Date(slot.endtime)).getTime()) {
          found = 1
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Conflicting Slot Time',
              icon: 'XIcon',
              variant: 'warning',
            },
          })
        }
        if (
          type === 'start' && (
            (
              ((new Date(check)).getTime() + (60000 * 25)) > (new Date(slot.begintime)).getTime() && (new Date(check)).getTime() < (new Date(slot.endtime)).getTime()
            ) || (
              ((new Date(check)).getTime() - (60000 * 10)) < (new Date(slot.endtime)).getTime() && (new Date(check)).getTime() > (new Date(slot.begintime)).getTime()
            )
          )
        ) {
          found = 1
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Conflicting Default Break Time',
              icon: 'XIcon',
              variant: 'warning',
            },
          })
        }
        if (
          type === 'end' && (
            (
              ((new Date(check)).getTime() + (60000 * 10)) > (new Date(slot.begintime)).getTime() && (new Date(check)).getTime() < (new Date(slot.endtime)).getTime()
            ) || (
              ((new Date(check)).getTime() - (60000 * 25)) < (new Date(slot.endtime)).getTime() && (new Date(check)).getTime() > (new Date(slot.begintime)).getTime()
            )
          )
        ) {
          found = 1
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Conflicting Default Break Time',
              icon: 'XIcon',
              variant: 'warning',
            },
          })
        }
        return 0
      })
      this.bookedslots.map(slot => {
        if ((new Date(check)).getTime() > (new Date(slot.begindatetimestamp)).getTime() && (new Date(check)).getTime() < (new Date(slot.begindatetimestamp)).getTime()) {
          found = 1
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Conflicting Slot Time',
              icon: 'XIcon',
              variant: 'warning',
            },
          })
        }
        if (
          type === 'start' && (
            (
              ((new Date(check)).getTime() + (60000 * 25)) > (new Date(slot.begindatetimestamp)).getTime() && (new Date(check)).getTime() < (new Date(slot.begindatetimestamp)).getTime()
            ) || (
              ((new Date(check)).getTime() - (60000 * 10)) < (new Date(slot.begindatetimestamp)).getTime() && (new Date(check)).getTime() > (new Date(slot.begindatetimestamp)).getTime()
            )
          )
        ) {
          found = 1
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Conflicting Default Break Time',
              icon: 'XIcon',
              variant: 'warning',
            },
          })
        }
        if (
          type === 'end' && (
            (
              ((new Date(check)).getTime() + (60000 * 10)) > (new Date(slot.begindatetimestamp)).getTime() && (new Date(check)).getTime() < (new Date(slot.begindatetimestamp)).getTime()
            ) || (
              ((new Date(check)).getTime() - (60000 * 25)) < (new Date(slot.begindatetimestamp)).getTime() && (new Date(check)).getTime() > (new Date(slot.begindatetimestamp)).getTime()
            )
          )
        ) {
          found = 1
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Conflicting Default Break Time',
              icon: 'XIcon',
              variant: 'warning',
            },
          })
        }
        return 0
      })
      if (found) {
        return false
      }
      return true
    },
    checkTimeConflict(check, type) {
      let found = 0
      if (type === 'end' && ((new Date(check)).getTime() <= (new Date(this.beginTime)).getTime() || (new Date(check)).getTime() - (60000 * 15) <= (new Date(this.beginTime)).getTime())) {
        found = 1
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'End time can not be less than or equal to begin time and must be a minimum 15 minute slot',
            icon: 'XIcon',
            variant: 'warning',
          },
        })
      }
      if (found) {
        return false
      }
      return true
    },

    normalizer(node) {
      return {
        id: node.id,
        label: node.title,
        children: node.programs_operationstables,
      }
    },
    milestonesnormalizer(node) {
      return {
        id: node.id,
        label: node.title,
        children: node.programs_progressstagetable,
      }
    },
    exercisesnormalizer(node) {
      return {
        id: node.id,
        label: node.title,
        children: node.programs_assignmenttable,
      }
    },
    filterNormalizer(node) {
      return {
        id: node.title,
        label: node.title,
        children: node.programs_operationstables,
      }
    },
    toTimestamp(strDate) {
      const datum = Date.parse(strDate)
      return datum / 1000
    },
    setAvailability(data) {
      // console.log(data.count_all_participanttables.aggregate.count)
      this.selectedRowId = data.id
      this.availableslots = data.sessions_participanttables[0].sessions_slots_tables
      this.availabilitycount = data.count_all_participanttables.aggregate.count
    },
    async getAvailableMembers(slotbegindatetimestamp, slotenddatetimestamp, rowData) {
      try {
        const response = await this.$apollo.query({
          query: gql`
            query availableMembers {
              sessions_participanttable(where: {sessions_slots_tables: {begindatetimestamp: {_eq: "${slotbegindatetimestamp}"}, enddatetimestamp: {_eq: "${slotenddatetimestamp}"}, is_accepted: {_eq: true}}, sessions_schedule_id: {_eq: ${this.selectedRowId}}}) {
                id
                programs_startupparticipant {
                  programs_applicantstable {
                    users_organizationtable {
                      title
                    }
                    users_customuser {
                      full_name
                    }
                  }
                }
                programs_partnerstable {
                  users_customuser {
                    full_name
                  }
                  users_organizationtable {
                    title
                  }
                }
              }
            }`,
        })
        return ({
          data: response.data.sessions_participanttable,
          row: rowData,
        })
      } catch (error) {
        // console.log(error)
        return null
      }
    },
    addtoselectslot() {
      if (this.slotbeginTime) {
        if (this.slotendTime) {
          this.selectedslots.push({
            begintime: this.slotbeginTime,
            endtime: this.slotendTime,
          })
          this.slotbeginTime = ''
          this.slotendTime = ''
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Select End Time',
              icon: 'XIcon',
              variant: 'warning',
            },
          })
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Select Begin Time',
            icon: 'XIcon',
            variant: 'warning',
          },
        })
      }
    },
    removeselectedslot(index) {
      this.selectedslots.splice(index, 1)
    },
    toggleChoice(value) {
      if (this.scheduleChoice === value) {
        this.scheduleChoice = ''
      } else {
        this.scheduleChoice = value
      }
    },
    bookSlot(rowData) {
      // console.log(rowData)
      this.$apollo.mutate({
        mutation: gql`
        mutation updateSession {
          update_sessions_scheduletable_by_pk(pk_columns: {id: ${this.selectedRowId}}, _set: {
            begin_time: "${rowData.begindatetimestamp}",
            end_time: "${rowData.enddatetimestamp}",
          }) {
            id
          }
        }`,
        update: (store, { data: { update_sessions_scheduletable_by_pk } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: update_sessions_scheduletable_by_pk.id ? 'Booked successfully' : 'Failed to book',
              icon: update_sessions_scheduletable_by_pk.id ? 'CheckIcon' : 'XIcon',
              variant: update_sessions_scheduletable_by_pk.id ? 'success' : 'warning',
            },
          })
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
    getAllScheduleParticipants() {
      const temp = []
      this.scheduleParticipantIds.map(participant => {
        this.scheduleslotRequests.map(slot => {
          temp.push({
            begindatetimestamp: slot.begin,
            enddatetimestamp: slot.end,
            is_visible: false,
            is_available: false,
            is_slotalternative: true,
            sessions_participant_id: participant.id,
          })
          return 0
        })
        return 0
      })
      return temp
    },
    addSchedule() {
      if (this.scheduleChoice === 'direct') {
        this.$apollo.mutate({
          mutation: gql`
          mutation updateSession {
            update_sessions_scheduletable_by_pk(pk_columns: {id: ${this.selectedRowId}}, _set: {
              begin_time: "${this.schedulebeginTime}",
              end_time: "${this.scheduleendTime}",
            }) {
              id
            }
          }`,
          update: (store, { data: { update_sessions_scheduletable_by_pk } }) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: update_sessions_scheduletable_by_pk.id ? 'Added successfully' : 'Failed to add',
                icon: update_sessions_scheduletable_by_pk.id ? 'CheckIcon' : 'XIcon',
                variant: update_sessions_scheduletable_by_pk.id ? 'success' : 'warning',
              },
            })
            this.$apollo.queries.rows.refetch()
            this.mutationLoading = false
          },
        })
      } else if (this.scheduleChoice === 'addslot') {
        this.$apollo.mutate({
          mutation: gql`
          mutation insertSessions($objects: [sessions_slots_table_insert_input!]!) {
            insert_sessions_slots_table (objects: $objects) {
              affected_rows
            }
          }`,
          variables: {
            objects: this.getAllScheduleParticipants(),
          },
          update: (store, { data: { insert_sessions_slots_table } }) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: insert_sessions_slots_table.affected_rows ? 'Added successfully' : 'Failed to add',
                icon: insert_sessions_slots_table.affected_rows ? 'CheckIcon' : 'XIcon',
                variant: insert_sessions_slots_table.affected_rows ? 'success' : 'warning',
              },
            })
            this.$apollo.queries.rows.refetch()
            this.mutationLoading = false
          },
        })
      }
    },
    updateSlotsMutationObject() {
      const { updated, added, deleted } = this.listObjectChanges(this.incubatorSlots, this.newIncubatorSlots)
      // Add incubator ID & user ID
      const addedItems = added.map(slot => ({
        ...slot,
        incubator_id: getUserData().associatedOrgDetails.filter(e => e.role === 'superadmin')[0].organization_id,
        incubator_user_id: getUserData().id,
      }))
      // Soft Delete
      const deleteItems = deleted.map(slot => ({
        ...slot,
        is_deleted: true,
      }))
      // Merge all
      return [...addedItems, ...updated, ...deleteItems]
    },
    updateSlots() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
        mutation insertSessions($objects: [sessions_slots_table_insert_input!]!) {
          insert_sessions_slots_table ( on_conflict: { constraint: slots_table_pkey, update_columns: [begindatetimestamp, enddatetimestamp, is_deleted] }, objects: $objects)
            {
            affected_rows
          }
        }`,
        variables: {
          objects: this.updateSlotsMutationObject(),
        },
        update: (store, { data: { insert_sessions_slots_table } }) => {
          if (insert_sessions_slots_table.affected_rows) {
            this.showAlert('Slots updated successfully', 'success')
          } else {
            this.showAlert('Failed to update slots', 'danger')
          }
          this.$apollo.queries.incubatorSlots.refetch()
          this.mutationLoading = false
        },
      })
    },
    getAllParticipants() {
      const temp = []
      if (this.selectedStartup) {
        this.selectedStartup.map(startup => {
          let obj = {
            startup_organization_id: startup,
          }
          if (this.requestSlot) {
            obj = {
              ...obj,
              sessions_slots_tables: {
                data: this.slotRequests.map(slot => ({
                  begindatetimestamp: slot.begin,
                  enddatetimestamp: slot.end,
                  is_visible: false,
                  is_available: false,
                  is_slotalternative: true,
                })),
              },
            }
          }
          temp.push(obj)
          return 0
        })
      }
      if (this.selectedPartner) {
        this.selectedPartner.map(partner => {
          let obj = {
            partner_organization_id: partner.id,
          }
          if (this.requestSlot) {
            obj = {
              ...obj,
              sessions_slots_tables: {
                data: this.slotRequests.map(slot => ({
                  begindatetimestamp: slot.begin,
                  enddatetimestamp: slot.end,
                  is_visible: false,
                  is_available: false,
                  is_slotalternative: true,
                })),
              },
            }
          }
          temp.push(obj)
          return 0
        })
      }
      if (this.selectedMentor) {
        this.selectedMentor.map(mentor => {
          let obj = {
            mentor_user_id: mentor.id,
          }
          if (this.requestSlot) {
            obj = {
              ...obj,
              sessions_slots_tables: {
                data: this.slotRequests.map(slot => ({
                  begindatetimestamp: slot.begin,
                  enddatetimestamp: slot.end,
                  is_visible: false,
                  is_available: false,
                  is_slotalternative: true,
                })),
              },
            }
          }
          temp.push(obj)
          return 0
        })
      }
      return temp
    },
    addMeeting() {
      this.mutationLoading = true
      if (this.choice === 'direct') {
        this.$apollo.mutate({
          mutation: gql`
          mutation insertSessions($data: [sessions_participanttable_insert_input!]!) {
            insert_sessions_scheduletable_one(object: {
              description: "${this.description}",
              incubator_id: ${getUserData().associatedOrgDetails.filter(e => e.role === 'superadmin')[0].organization_id},
              incubator_user_id: ${getUserData().id},
              title: "${this.title}",
              sessions_participanttables: {data: $data},
              ${this.selectedOperation ? `
                operations_id: ${this.selectedOperation},
              ` : ''}
              ${this.selectedExercise ? `
                milestone_id: ${this.selectedExercise},
              ` : ''}
              ${this.selectedMilestone ? `
                exercise_id: ${this.selectedMilestone},
              ` : ''}
              ${this.requestSlot ? `
                is_request: "true",
              ` : `
                begin_time: "${this.beginTime}",
                end_time: "${this.endTime}",
              `}
            }) {
              id
            }
          }`,
          variables: {
            data: this.getAllParticipants(),
          },
          update: (store, { data: { insert_sessions_scheduletable_one } }) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: insert_sessions_scheduletable_one.id ? 'Added successfully' : 'Failed to add',
                icon: insert_sessions_scheduletable_one.id ? 'CheckIcon' : 'XIcon',
                variant: insert_sessions_scheduletable_one.id ? 'success' : 'warning',
              },
            })
            this.$apollo.queries.rows.refetch()
            this.mutationLoading = false
          },
        })
      } else if (this.choice === 'slot') {
        this.$apollo.mutate({
          mutation: gql`
          mutation insertSessions {
            insert_sessions_scheduletable_one(object: {
              description: "${this.description}",
              begin_time: "${this.selectedSlot.begindatetimestamp}",
              end_time: "${this.selectedSlot.enddatetimestamp}",
              incubator_id: ${getUserData().associatedOrgDetails.filter(e => e.role === 'superadmin')[0].organization_id},
              incubator_user_id: ${getUserData().id},
              title: "${this.title}",
              sessions_participanttables: { data : [{
                ${this.startuporpartner === 'Startup' ? `
                  startup_organization_id: ${this.selectedStartup}
                ` : ''}
                ${this.startuporpartner === 'Partner' ? `
                  partner_organization_id: ${this.selectedPartner.id}
                ` : ''}
                ${this.startuporpartner === 'Mentor' ? `
                  mentor_user_id: ${this.selectedMentor.id}
                ` : ''}
                }]
              },
            }) {
              id
            }
          }`,
          update: () => {
            this.$apollo.mutate({
              mutation: gql`
              mutation updateSlot {
                update_sessions_slots_table_by_pk(pk_columns: {id: ${this.selectedSlot.id}}, _set: {is_available: false}) {
                  id
                }
              }`,
              update: (store, { data: { update_sessions_slots_table_by_pk } }) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: update_sessions_slots_table_by_pk.id ? 'Added successfully' : 'Failed to add',
                    icon: update_sessions_slots_table_by_pk.id ? 'CheckIcon' : 'XIcon',
                    variant: update_sessions_slots_table_by_pk.id ? 'success' : 'warning',
                  },
                })
                this.$apollo.queries.rows.refetch()
                this.mutationLoading = false
              },
            })
          },
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please Choose Schedule Type',
            icon: 'XIcon',
            variant: 'warning',
          },
        })
      }
    },
    updateLinks() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
        mutation {
          update_sessions_scheduletable_by_pk(pk_columns: {id: ${this.rows[this.selectedRow].id} }, _set: {recording_link: "${this.rows[this.selectedRow].recording_link}", meeting_link: "${this.rows[this.selectedRow].meeting_link}"}) {
            id
          }
        }`,
        update: (store, { data: { update_sessions_scheduletable_by_pk } }) => {
          // this.$apollo.mutate({
          //   mutation: gql`mutation MyMutation($objects: [programs_meetparticipanttable_insert_input!]!) {
          //     delete_programs_meetparticipanttable(where: {schedule_id_id: {_eq: ${update_sessions_scheduletable_by_pk.id}}}) {
          //       affected_rows
          //     }
          //     insert_programs_meetparticipanttable(objects: $objects) {
          //       affected_rows
          //     }
          //   }`,
          //   variables: {
          //     objects: this.rows[this.selectedRow].programs_meetparticipanttables,
          //   },
          //   update: () => {
          //     this.$toast({
          //       component: ToastificationContent,
          //       props: {
          //         title: 'Updated successfully',
          //         icon: 'CheckIcon',
          //         variant: 'success',
          //       },
          //     })
          //     this.$apollo.queries.rows.refetch()
          //     this.mutationLoading = false
          //   },
          // })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: update_sessions_scheduletable_by_pk.id ? 'Updated successfully' : 'Failed to update',
              icon: update_sessions_scheduletable_by_pk.id ? 'CheckIcon' : 'XIcon',
              variant: update_sessions_scheduletable_by_pk.id ? 'success' : 'warning',
            },
          })
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
    updateParticipants() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation MyMutation($objects: [programs_meetparticipanttable_insert_input!]!) {
          delete_programs_meetparticipanttable(where: {schedule_id_id: {_eq: ${this.rows[this.selectedRow].id}}}) {
            affected_rows
          }
          insert_programs_meetparticipanttable(objects: $objects) {
            affected_rows
          }
        }`,
        variables: {
          objects: this.rows[this.selectedRow].programs_meetparticipanttables,
        },
        update: () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Updated successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
  },
  apollo: {
    rows: {
      query() {
        return gql`
        query rows{
          sessions_scheduletable(order_by: {begin_time: desc}, where: {incubator_id: {_eq: ${getUserData().associatedOrgDetails.filter(e => e.role === 'superadmin')[0].organization_id}}}) {
            id
            begin_time
            end_time
            description
            type
            title
            meeting_link
            recording_link
            sessions_participanttables(order_by: {id: asc}) {
              id
              sessions_slots_tables {
                begindatetimestamp
                enddatetimestamp
              }
            }
            count_all_participanttables: sessions_participanttables_aggregate {
              aggregate {
                count(columns: id)
              }
            }
            count_rated_participanttables: sessions_participanttables_aggregate(where: { ratings: { _is_null: false } }) {
              aggregate {
                count
                avg {
                  ratings
                }
              }
            }
          }
        }`
      },
      update: data => data.sessions_scheduletable,
    },
    operations: {
      query() {
        return gql`{
            programs_operationstable(where: {program_id: {_eq: ${this.$route.params.id}}, operations_type: {_eq: "SubPhase"}}) {
                  id
                  title
                }
              }`
      },
      update: data => data.programs_operationstable,
    },
    startups: {
      query() {
        return gql`query startups{
          users_associationtable(where: {incubator_id: {_eq: ${getUserData().associatedOrgDetails.filter(e => e.role === 'superadmin')[0].organization_id}}, role: {_in: ["pre-incubatee", "incubatee", "graduate"]}}) {
            id
            role
            users_organizationtable {
              id
              title
            }
          }
        }`
      },
      update: data => data.users_associationtable,
    },
    partners: {
      query() {
        return gql`{
          users_associationtable(where: {incubator_id: {_eq: ${getUserData().associatedOrgDetails.filter(e => e.role === 'superadmin')[0].organization_id}}, role: {_in: ["partner", "mentor"]}}) {
            id
            role
            users_organizationtable {
              id
              title
            }
            users_customuser {
              id
              full_name
            }
          }
        }`
      },
      update: data => data.users_associationtable.map(e => {
        if (e.users_customuser) {
          return ({
            ...e.users_customuser,
            partner_id: e.id,
            role: e.role,
            type: 'mentor',
          })
        }
        return ({
          ...e.users_organizationtable,
          partner_id: e.id,
          role: e.role,
          type: 'partner',
        })
      }),
    },
    choicebookedslots: {
      query() {
        const tempdate = new Date(this.choiceslotdate)
        return gql`
        query choiceBookedSlots{
          sessions_slots_table(
            where: {
              begindatetimestamp: {_gte: "${(new Date(this.choiceslotdate).toLocaleString('en-US', { timeZone: getUserData().timezone.abbr }))}", _lt: "${(new Date(tempdate.setDate(tempdate.getDate() + 1)).toLocaleString('en-US', { timeZone: getUserData().timezone.abbr }))}"},
              _or: [
                {
                  incubator_id: {_eq: ${getUserData().associatedOrgDetails.filter(e => e.role === 'superadmin')[0].organization_id}}
                },
                {
                  is_general: {_eq: true}
                }
              ],
              ${this.startuporpartner === 'Startup' && this.selectedStartup ? `
                startup_organization_id: {_eq: ${this.selectedStartup}}
              ` : ''}
              ${this.startuporpartner === 'Partner' || this.startuporpartner === 'Mentor' ? `
                ${this.selectedPartner.type === 'mentor' ? `
                  mentor_user_id: {_eq: ${this.selectedPartner.id}}
                ` : ''}
                ${this.selectedPartner.type === 'partner' ? `
                  partner_organization_id: {_eq: ${this.selectedPartner.id}}
                ` : ''}
              ` : ''}
            }
          ) {
            begindatetimestamp
            enddatetimestamp
            id
            is_available
          }
        }`
      },
      update: data => data.sessions_slots_table,
    },
    exercises: {
      query() {
        return gql`{
          programs_assignmenttable(where: {_or: {program_id: {_eq: ${this.$route.params.id}}, programs_operationstable: {program_id: {_eq: ${this.$route.params.id}}}}}) {
            id
            title
          }
        }`
      },
      update: data => data.programs_assignmenttable,
    },
    milestones: {
      query() {
        return gql`{
          programs_progressstagetable(where: {startup_participant_id: {_eq: ${this.selectedStartup && this.selectedStartup.length === 1 ? this.selectedStartup[0] : '0'}}}, order_by: {id: desc}) {
            id
            title
          }
        }`
      },
      update: data => data.programs_progressstagetable,
    },
    bookedslots: {
      query() {
        const tempdate = new Date(this.slotdate)
        return gql`
        query bookedSlots{
          sessions_slots_table(
            where: {
              program_id: {_eq: ${this.$route.params.id}},
              incubator_id: {_eq: ${getUserData().associatedOrgDetails.filter(e => e.role === 'superadmin')[0].organization_id}},
              incubator_user_id: {_eq: ${getUserData().id}},
              begindatetimestamp: {_gte: "${(new Date(this.slotdate).toLocaleString('en-US', { timeZone: getUserData().timezone.abbr }))}", _lt: "${(new Date(tempdate.setDate(tempdate.getDate() + 1)).toLocaleString('en-US', { timeZone: getUserData().timezone.abbr }))}"}
            }
          ) {
            begindatetimestamp
            enddatetimestamp
            id
            is_available
          }
        }`
      },
      update: data => data.sessions_slots_table,
    },
    // Fetch existing incubator's slots
    incubatorSlots: {
      query() {
        return gql`
        query incubatorSlots($incubator_id: Int!, $current_date: timestamptz!) {
          sessions_slots_table(where: {
                incubator_id: {_eq: $incubator_id },
                begindatetimestamp: {_gte: $current_date}
            }) {
            begindatetimestamp
            enddatetimestamp
            id
          }
        }`
      },
      variables: {
        incubator_id: getUserData().associatedOrgDetails.filter(e => e.role === 'superadmin')[0].organization_id,
        current_date: new Date().toISOString(),
      },
      update: data => data.sessions_slots_table,
    },
    // Fetch associated entities for incubator
    incubatorAssociations: {
      query() {
        return gql`
            query incubatorAssociations($incubator_ids: [Int!]!) {
              users_associationtable(where: {incubator_id: {_in: $incubator_ids }, role: {_in: ["pre-incubatee", "incubatee", "graduate", "partner", "mentor"]}}) {
                id
                role
                users_organizationtable {
                  id
                  title
                }
                users_customuser {
                  id
                  full_name
                }
              }
            }`
      },
      variables() {
        return {
          incubator_ids: getUserData().associatedOrgDetails.filter(e => e.role === 'superadmin').map(e => e.organization_id),
        }
      },
      update(data) {
        return data.users_associationtable.map(e => {
          if (e.role === 'partner') {
            return {
              ...e.users_organizationtable,
              title: e.users_organizationtable.title,
              association_id: e.id,
              type: 'partner',
            }
          }
          if (e.role === 'mentor') {
            return {
              ...e.users_customuser,
              full_name: e.users_customuser.full_name,
              association_id: e.id,
              type: 'mentor',
            }
          }
          return {
            ...e.users_organizationtable,
            title: e.users_organizationtable.title,
            association_id: e.id,
            type: 'startup',
            role: e.role,
          }
        })
      },
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker';
@import '@core/scss/vue/libs/vue-select';
@import '@core/scss/vue/libs/vue-good-table';
</style>

<style>
  .timepicker-ui-circle-hand{
    background-color: #7367F0;
  }
  .timepicker-ui-dot{
    background-color: #7367F0;
  }
  .timepicker-ui-clock-hand{
    background-color: #7367F0;
  }
  .timepicker-ui-cancel-btn, .timepicker-ui-ok-btn, .timepicker-ui-cancel-btn.mobile, .timepicker-ui-ok.btn-mobile{
    color: #7367F0;
  }
  .timepicker-ui-hour:hover, .timepicker-ui-hour.active, .timepicker-ui-minutes:hover, .timepicker-ui-minutes.active, .timepicker-ui-hour.mobile:hover, .timepicker-ui-hour.mobile.active, .timepicker-ui-minutes.mobile:hover, .timepicker-ui-minutes.mobile.active{
    color: #7367F0;
    background-color: #EEEDFD;
  }
  .timepicker-ui-am:hover, .timepicker-ui-am.active, .timepicker-ui-pm:hover, .timepicker-ui-pm.active, .timepicker-ui-am.mobile:hover, .timepicker-ui-am.mobile.active, .timepicker-ui-pm.mobile:hover, .timepicker-ui-pm.mobile.active{
    color: #7367F0;
    background-color: #EEEDFD;
  }
</style>
